.modal {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__block {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 24px;
        padding: 12px;
        border: 1px solid $color-blue;
        border-radius: 6px;
        background: $color-white;
        min-height: 96px;
    }
    &__title {
        margin: 2px 44px 16px 0;
        font-size: 24px;
        font-weight: 700;
    }
    &__content {
        p {
            padding-bottom: 6px;
        }
    }
    &__close&__close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        border-color: $color-blue;
        padding: 0;
        width: 36px;
        height: 36px;
    }
}
.dark-mode .modal__block {
    border-color: #2d3748;
    background-color: #1a202c;
}