.splide {
  &--draggable {
    > .splide__track {
      > .splide__list {
        > .splide__slide {
          user-select: none;
        }
      }
    }
  }
}