.order {
	padding-bottom: 0;
	&.m--center {
		margin-top: auto;
		margin-bottom: auto;
		padding-bottom: 70px;
	}
	&__alert {
		font-size: 20px;
		font-weight: bold;
		color: $color-blue;
		width: 100%;
	}
	&__block {
		margin-bottom: 35px;
		&-title {
			position: relative;
			color: $color-blue;
			padding-right: 240px;
			@mixin responsive-le-s {
				display: flex;
				flex-direction: column-reverse;
				padding-right: 0;
			}
			&.m--summ {
				color: $color-black;
				span {
					color: $color-blue;
				}
			}
		}
		&-inner {
			position: relative;
			padding: 60px 65px 20px;
			margin: 0 -65px;
			background: $color-white;
		}
		@mixin responsive-le-l {
			&-inner {
				padding: 60px 40px 20px;
				margin: 0 -40px;
			}
		}
		@mixin responsive-le-m {
			&-inner {
				padding: 60px 30px 20px;
				margin: 0 -30px;
			}
		}
		@mixin responsive-le-xs {
			&-inner {
				padding: 60px 20px 20px;
				margin: 0 -20px;
			}
		}
		@mixin responsive-xxs {
			&-inner {
				padding: 60px 10px 20px;
				margin: 0 -10px;
			}
		}
		&-description {
			margin-bottom: 30px;

			&-title {
				font-size: 24px;
				line-height: 30px;
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom: 8px;
			}
		}
	}
	&__params {
		position: relative;
		display: flex;
		margin-bottom: 35px;
		&-dates,
		&-rooms {
			padding-left: 35px;
			padding-right: 20px;
			color: $color-middlegray;
		}
		&-dates {
			background: url('./assets/img/icons/calendar.svg') 0 50% no-repeat;
			background-size: 24px 24px;
		}
		&-rooms {
			background: url('./assets/img/icons/peoples.svg') 0 50% no-repeat;
			background-size: 24px 24px;
		}
	}
	&__rooms {
		position: relative;
		display: block;
		/*max-width: 980px;*/
		border-radius: 10px;
		box-shadow: 0px 4px 24px rgba(24, 73, 129, 0.2);
		margin-bottom: 35px;
		&-header {
			font-weight: bold;
			text-transform: uppercase;
		}
		&-header,
		&-row {
			display: grid;
			/*grid-template-columns: 50px auto 200px 200px 30%;*/
			grid-template-columns: 50px auto 200px 35%;
			padding: 15px 25px;
			font-size: 24px;
			line-height: 30px;
			border-bottom: 1px solid $color-lightgray;
			@mixin responsive-le-m {
				/*grid-template-columns: 30px auto 150px 150px 30%;*/
				grid-template-columns: 30px auto 150px 35%;
				font-size: 18px;
				line-height: 22px;
			}
			@mixin responsive-le-s {
				grid-template-columns: 30px auto 50%;
				grid-template-areas: "A B C" "A D D";
			}
			@mixin responsive-xxs {
				grid-template-columns: 30px auto;
				grid-template-areas: "A B" "A C" "A D" "A E";
			}
		}
		&-row {
			color: $color-blue;
		}
		&-params {
			padding-left: 20px;
			&.m--advanced {
				font-size: 22px;
			}
		}
		&-param {
			display: grid;
			grid-template-columns: auto 30px;
			&-value {
				text-align: right;
			}
		}
		@mixin responsive-le-m {
			&-params.m--advanced {
				font-size: 18px;
				line-height: 22px;
			}
		}
		@mixin responsive-le-s {
			&-position {
				grid-area: A;
			}
			&-name {
				grid-area: B;
				margin-bottom: 10px;
			}
			&-params {
				margin-bottom: 10px;
			}
			&-params.m--food {
				grid-area: C;
			}
			&-params.m--actions {
				padding-left: 0;
				grid-area: D;	
			}
			&-params.m--advanced {
				padding-left: 0;
				grid-area: D;	
			}
		}
		@mixin responsive-xxs {
			&-params {
				padding-left: 0;
			}
		}
	}
	&__specials {
		font-size: 24px;
		line-height: 30px;
		padding-bottom: 20px;		
		@mixin responsive-le-m {
			font-size: 18px;
			line-height: 22px;
		}
	}
	&__total {
		/*
		font-size: 48px;
		line-height: 58px;
		font-weight: bold;
		*/
		color: $color-black;
		span {
			color: $color-blue;
		}
	}
	&__devider {
		position: relative;
		display: block;
		height: 0;
		border-bottom: 1px solid $color-middlegray;
		margin: 30px 0 40px;
	}
	&__info {
		&-tabs {
			/*margin-bottom: 40px;*/
			&-menu {
				position: relative;
				display: flex;
				padding-left: 35px;
				@mixin responsive-le-s {
					flex-wrap: wrap;
				}
			}
			&-item {
				position: relative;
				display: block;
				font-size: 24px;
				line-height: 24px;
				font-weight: bold;
				text-decoration: underline;
				color: $color-blue;
				margin: 0 30px 0 35px;
				transition: color 0.5s;
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: -35px;
					height: 24px;
					width: 24px;
					background: url('./assets/img/icons/arrow.svg') 50% 50% no-repeat;
					background-size: 24px auto;
					transform: scaleY( 1 );
				}
				&:hover,
				&.is-active {
					text-decoration: none;
					color: $color-gray;
				}
				&.is-active {
					&:before {
						transform: scaleY( -1 );
					}
				}
				@mixin responsive-le-m {
					font-size: 18px;
					line-height: 20px;
					margin: 0 10px 0 35px;
				}
				@mixin responsive-le-s {
					width: calc(50% - 45px);
					margin-bottom: 30px;
				}
				@mixin responsive-le-xs {
					width: calc(100% - 45px);
				}
			}
			&-blocks {
				padding-top: 30px;
				@mixin responsive-le-s {
					padding-top: 0;
				}
			}
			&-block {
				position: relative;
				display: none;
				min-height: 60px;
				padding: 15px 55px;
				margin-top: 20px;
				border-radius: 10px;
				background: $color-white;
				filter: drop-shadow(0px 4px 24px rgba(24, 73, 129, 0.2));
				&.is-opened {
					display: block;
				}
				@mixin responsive-le-xs {
					padding-left: 20px;
				}
			}
			&-close {
				position: absolute;
				top: 10px;
				right: 15px;
				width: 32px;
				height: 32px;
				border: 1px solid $color-middlegray;
				border-radius: 50%;
				background: url('./assets/img/icons/close-gray.svg') 50% 50% no-repeat;
				background-size: 24px 24px;
			}
			&-arrow {
				position: absolute;
				top: -45px;
				left: 50%;
				margin-left: -27px;
				width: 54px;
				height: 54px;
				background: url('./assets/img/icons/room-arrow-more.svg') 50% 50% no-repeat;
				background-size: 54px 54px;
			}
		}
		&-content {
			.h1 {
				font-size: 24px;
				line-height: 28px;
				font-weight: 500;
				color: $color-middlegray;
				margin-bottom: 20px;
			}
		}
	}
	&__contacts {
		&-form {
			max-width: none;
		}
	}
	&__guests {
		margin-bottom: 60px;
		&-form {
			max-width: none;
		}
	}
	&__times {
		&-table {
			position: relative;
			display: block;
			/*max-width: 980px;*/
			border-radius: 10px;
			box-shadow: 0px 4px 24px rgba(24, 73, 129, 0.2);
			margin-bottom: 35px;
		}
		&-header {
			font-weight: bold;
			text-transform: uppercase;
		}
		&-header,
		&-row {
			display: grid;
			/*grid-template-columns: 50px auto 200px 200px 30%;*/
			grid-template-columns: 50px auto 200px 200px;
			padding: 15px 25px;
			font-size: 24px;
			line-height: 30px;
			border-bottom: 1px solid $color-lightgray;
			@mixin responsive-le-m {
				/*grid-template-columns: 30px auto 150px 150px 30%;*/
				grid-template-columns: 30px auto 160px 160px;
				font-size: 18px;
				line-height: 22px;
			}
			@mixin responsive-le-s {
				grid-template-columns: 30px auto 160px;
				grid-template-areas: "A B C" "A B D";
			}
		}
		&-row {
			color: $color-blue;
		}
		&-param {
			padding-left: 20px;
		}
		@mixin responsive-le-s {
			&-position {
				grid-area: A;
			}
			&-name {
				grid-area: B;
				margin-bottom: 0;
			}
			&-param.m--start {
				margin-bottom: 10px;
				grid-area: C;
			}
			&-param.m--stop {
				margin-bottom: 0;
				grid-area: D;
			}
		}
		@mixin responsive-xxs {
			&-param {
				padding-left: 0;
			}
		}
		&-form {
			max-width: none;
		}
	}

	&__advanced {
		&-form {
			max-width: none;
		}
	}
	&__button {
		.spinner {
			margin-right: 10px;
			.path {
				stroke: $color-white;
			}
		}
	}
	&__payments {
		&-content {
			margin-bottom: 30px;
		}
		&-block {}
		&-item {
			background: $color-white;
			border-radius: 10px;
			padding: 25px 40px;
			margin-bottom: 25px;
			&-block {
				position: relative;
				display: flex;
				justify-content: space-between;
			}
			&-content {
				width: 75%;
			}
			&-alert {
				padding-top: 20px;
				color: $color-red;
			}
			&-button {
				margin-left: 20px;
				align-self: flex-end;
				.spinner {
					margin-right: 10px;
					.path {
						stroke: $color-white;
					}
				}
			}
			&-registration {
				display: inline-flex;
				margin-bottom: 20px;
			}
			&-label {
				position: absolute;
				display: block;
				top: 0;
				right: -40px;
				border-radius: 8px;
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				padding: 8px 12px;
				color: $color-white;
				background: $color-slightblue;
				box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			}
			@mixin responsive-le-s {
				&-block {
					flex-wrap: wrap;
				}
				&-content {
					width: 100%;
					margin-bottom: 30px;
				}
				&-button {
					margin-left: auto;
					margin-right: auto;
				}
				&-label {
					right: -40px;
					position: relative;
					top: auto;
					margin-left: auto;
					margin-bottom: 20px;
				}
			}
			@mixin responsive-le-xs {
				padding: 25px 20px;
				&-label {
					right: -20px;
				}
			}
		}
	}
	&__notice {
		&-title {
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 20px;
		}
		&-content {}
	}
	&__result {
		position: relative;
		padding-left: 210px;
		max-width: 1120px;
		margin: auto;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 150px;
			width: 150px;
			background: url('./assets/img/icons/result-check.svg') 50% 50% no-repeat;
			background-size: 150px auto;
		}
		&.is-alert:before {
			height: 175px;
			width: 150px;
			background: url('./assets/img/icons/result-alert.svg') 50% 50% no-repeat;
		}
		@mixin responsive-le-l {
			padding-left: 200px;
			&:before {
				height: 125px;
				width: 125px;
				background-size: 125px auto;
			}
			&.is-alert:before {
				height: 160px;
				width: 125px;
				background-size: 125px auto;
			}
		}
		@mixin responsive-le-m {
			padding-left: 150px;
			&:before {
				height: 100px;
				width: 100px;
				background-size: 100px auto;
			}
			&.is-alert:before {
				height: 127px;
				width: 100px;
				background-size: 100px auto;
			}
		}
		@mixin responsive-le-s {
			padding-left: 125px;
		}
		@mixin responsive-le-xs {
			padding-left: 80px;
			&:before {
				height: 64px;
				width: 64px;
				background-size: 64px auto;
			}
			&.is-alert:before {
				height: 82px;
				width: 64px;
				background-size: 64px auto;
			}
		}
		@mixin responsive-xxs {
			padding-left: 0;
			padding-top: 80px;
			&:before {
				left: 50%;
				margin-left: -32px;
			}
			&.is-alert {
				padding-top: 100px;
			}
		}
		&-item {
			span {
				color: $color-blue;
			}
		}
	}
	&__promo {
		display: flex;
		&-button {
			margin-top: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}
	}
}