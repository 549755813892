.splide {

  &--nav {
    > .splide__track {
      > .splide__list {
        > .splide__slide {
          /*border: 3px solid transparent;*/
          transition: opacity 0.2s;
          &.is-active {
            /*border-color: black;*/
            opacity: 0.5;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}