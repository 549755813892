@define-mixin __input {
    position: relative;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    background: $color-white;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 100%;
    outline: none;
    padding: 13px 15px;
    color: $color-gray;
    box-shadow: 0px 4px 4px rgba(24, 73, 129, 0.04), 0px 4px 16px rgba(24, 73, 129, 0.08);
    transition: all 0.7s;
}

@define-mixin input {
    @mixin __input;

    &::placeholder {
        color: $color-middlegray;
    }

    &.is-valid {
        border-color: $color-green;
        background-color: rgba($color-green, 0.03);
    }
    &.is-error {
        border-color: $color-red;
        background-color: rgba($color-red, 0.03);
        color: $color-red;
    }

    &:focus {
        border-color: $color-blue;
    }

    &:disabled {
        background-color: rgba($color-lightblack, 0.05);
        color: $color-gray;
        cursor: not-allowed;
    }

    &[readonly] {
        /*border-color: $color-gray-T4;*/
        /*background-color: rgba($color-lightblack, 0.02);*/
        color: $color-gray;
        cursor: default;
    }
    &.m--calendar {
        padding-right: 40px;
        appearance: none;
        background-image: url('./assets/img/icons/calendar.svg');
        background-position: calc(100% - 14px) 50%;
        background-repeat: no-repeat;
        background-size: 24px 24px;
    }
}

@define-mixin select {
    @mixin input;

    padding-right: 40px;
    appearance: none;
    background-image: url('./assets/img/icons/arrow.svg');
    background-position: calc(100% - 14px) 50%;
    background-repeat: no-repeat;
    background-size: 14px 8px;

    option {
        @mixin input;
        &:checked {
            background-image: url('./assets/img/icons/check.svg');
            background-position: calc(100% - 14px) 50%;
            background-repeat: no-repeat;
            background-size: 14px 8px;
        }
    }
    /*
    &::-ms-expand {
        display: none;
    }

    &.is-valid {
        background-image: svg-load('assets/img/select.svg', fill=$color-green);
    }

    &.is-error {
        background-image: svg-load('assets/img/select.svg', fill=$color-red);
    }

    &:focus {
        background-image: svg-load('assets/img/select.svg', fill=$color-blue);
    }

    &:disabled {
        background-image: svg-load('assets/img/select.svg', fill=$color-gray);
    }

    &[readonly] {
        background-image: svg-load('assets/img/select.svg', fill=$color-gray);
    }
    */
}

@define-mixin textarea {
    @mixin input;
    resize: none;

    min-height: 118px;
}

@define-mixin __input-box {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;

    & + label {
        position: relative;
        display: block;
        padding-left: 26px;
        font-size: 18px;
        line-height: 1.2;
        font-weight: medium;
        font-style: normal;
        color: $color-lightblack;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            width: 18px;
            height: 18px;
            border: 1px solid $color-lightblack;
        }

        &::after {
            content: '';
            display: none;
        }

        &:hover::before {
            box-shadow: 0 0 4px -2px $color-lightblack;
        }
    }

    &:checked + label {
        &::before {
            border-color: $color-blue;
            background-color: $color-blue;
        }

        &::after {
            display: block;
        }

        &:hover::before {
            box-shadow: 0 0 4px -2px $color-blue;
        }
    }

    &:disabled + label {
        color: $color-gray;
        cursor: not-allowed;

        &:before {
            opacity: 0.5;
        }

        &:hover::before {
            box-shadow: none;
        }
    }

    &[readonly] + label {
        color: $color-gray;
        cursor: default;

        &:hover::before {
            box-shadow: none;
        }
    }
}

@define-mixin checkbox {
    @mixin __input-box;

    & + label {
        &::before {
            border-radius: 1px;
        }

        &::after {
            position: absolute;
            left: 7px;
            top: 10px;
            width: 10px;
            height: 5px;
            border-bottom: 2px solid $color-white;
            border-left: 2px solid $color-white;
            transform: rotate(-45deg);
            transform-origin: 0 100% 0;
        }
    }
}

@define-mixin radiobox {
    @mixin __input-box;

    & + label {
        &::before {
            border-radius: 50%;
        }

        &::after {
            position: absolute;
            left: 5px;
            top: 7px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color-white;
        }
    }
}

@define-mixin button {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-gray;
    box-sizing: border-box;
    border-radius: 30px;
    height: 60px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-family: $font-family-base;    
    font-weight: 500;
    font-size: 34px;
    line-height: 34px;
    box-shadow: 0px 2px 4px rgba(24, 73, 129, 0.16);
    transition: color 0.7s, background 0.7s, border 0.7s;
    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &:active:not(:disabled) {
    }

    @mixin responsive-le-m {
        font-size: 20px;
        line-height: 20px;
        height: 44px;
    }

    &.m--small {
        border-radius: 24px;
        height: 48px;
        font-size: 18px;
        line-height: 18px;
    }
    &.m--uppercase {
        text-transform: uppercase;
    }
    &.m--icon {
        span {
            position: relative;
            display: block;
            padding-left: 35px;
            &:before {
                content: '';
                position: absolute;
                margin-top: -15px;
                top: 50%;
                left: 0;
                width: 30px;
                height: 30px;
            }
        }
        &-back {
            span:before {
                background: url('./assets/img/icons/arrow-back.svg') 50% 50% no-repeat;
                background-size: 24px;
            }
        }
        &-close {
            span:before {
                background: url('./assets/img/icons/close.svg') 50% 50% no-repeat;
                background-size: 30px;
            }
        }
        &-plus-white {
            span:before {
                background: url('./assets/img/icons/plus-white.svg') 50% 50% no-repeat;
                background-size: 30px;
            }
        }
    }
    &.m--circle {
        padding: 10px;
        width: 60px;
        span {
            padding-left: 0;
            width: 30px;
            height: 30px;
        }
        &.m--small {
            padding: 5px;
            width: 44px;
            height: 44px;
            span:before {
                background-size: 24px;
            }
        }
        @mixin responsive-le-m {
            padding: 6px;
            width: 44px;
            &.m--small {
                width: 44px;
                height: 44px;
            }
        }
    }
}

@define-mixin button--color $br-color, $bg-color, $label-color {
    color: $label-color;
    border-color: $br-color;
    background-color: $bg-color;

    &:hover:not(:disabled) {
        color: $label-color;
        border-color: $br-color;
        background-color: $bg-color;
    }
}
