.calendar {
	padding: 0;
	.splide {
		display: flex;
		flex-direction: column-reverse;
		&__arrows {
			display: flex;
			justify-content: space-between;
		}
		&__arrow {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			transform: none;
			border: none;
			background: transparent;
			&:before {
				position: absolute;
				top: 0;
				font-size: 18px;
				line-height: 40px;
				font-weight: bold;
				color: $color-blue;
			}
			&--prev:before {
				content: 'Раньше';
				left: 44px;
			}
			&--next:before {
				content: 'Позже';
				right: 44px;
			}
			&[disabled] {
				opacity: 0.3;
			}
		}
	}
	&__variants {
		position: relative;
		display: flex;
		font-size: 18px;
		line-height: 32px;
		font-weight: 700;
		margin-bottom: 8px;
		&-label {
			margin-right: 10px;
		}
		&-buttons {
			position: relative;
			display: flex;
		}
		&-button {
			width: 32px;
			height: 32px;
			border-radius: 0;
			padding: 0;
			font-size: 18px;
			line-height: 32px;
			margin-right: -1px;
			&:first-child {
				border-radius: 4px 0 0 4px;
				width: 36px;
			}
			&:last-child {
				border-radius: 0 4px 4px 0;
				width: 36px;
			}
		}
	}
	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		border-right: 1px solid $color-lightgray;
		&-day {
			text-align: right;
			color: $color-middlegray;
			padding: 0 5px;
			background: $color-middlewhite;
			height: 48px;
			font-size: 16px;
			line-height: 24px;
			}
		&-price {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			color: $color-lightblack;
			padding: 0 5px;
			background: $color-lightgray-da;
			height: 48px;
			font-size: 16px;
			line-height: 24px;
			border-bottom: 1px solid $color-lightgray-da;
			&.m--no-cursor:hover {
				cursor: default !important;
			}
			.m--currency {
				margin-left: 4px;
			}
			&.is-disabled {
				background-color: $color-white;
				background-image: url('./assets/img/icons/close-gray.svg');
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: 20px 20px;
			}
			&:not(.is-disabled) {
				transition: color 0.7s, background 0.7s, border 0.7s;
			}
			&:not(.is-disabled):hover {
				cursor: pointer;
				color: $color-white;
				background: $color-blue;
			}
		}
	}
	&__params {
		@mixin input
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 20px;
		max-height: 52px;
		&-button {
			margin: -14px -15px -14px 20px;
			border-radius: 0 4px 4px 0;
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			height: 50px;
			margin-left: auto;
		}
		&-dates {
			padding-left: 35px;
			padding-right: 20px;
			color: $color-middlegray;
		}
		&-dates {
			background: url('./assets/img/icons/calendar.svg') 0 50% no-repeat;
			background-size: 24px 24px;
		}

	    @mixin responsive-le-xl {
	    }

	    @mixin responsive-le-l {
	    }

	    @mixin responsive-le-m {
	    }

	    @mixin responsive-le-s {
	    	margin-right: 0;
	    	max-width: none;
	    }

	    @mixin responsive-le-xs {
			max-height: none;
			flex-wrap: wrap;
			&-button {
				margin: 14px -15px -14px -15px;
				width: calc(100% + 28px);
			}
	    }

	    @mixin responsive-xxs {
	    }
	}
}