.splide {
  &__pagination {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 50%;
    transform: translateX( -50% );
    padding: 0;

    &__page {
      display: inline-block;
      width: $dot-size;
      height: $dot-size;
      background: white;
      border-radius: calculate($dot-size / 2);
      margin: 3px;
      padding: 0;
      transition: width .2s linear;
      border: none;
      /*opacity: .7;*/

      &.is-active {
        /*transform: scale( 1.4 );*/
        background: white;
        width: calculate($dot-size * 4);
      }

      &:hover {
        cursor: pointer;
        opacity: .9;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
