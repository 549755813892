.tariffs {
	padding: 0;
	&__title {
		color: $color-blue;
		margin-bottom: 55px;
	}
	&__block {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		padding: 0;
		margin: 0 -10px;
	}
	&__item {
		position: relative;
		display: block;
		width: calc(33% - 20px);
		background: $color-middlewhite;
		border-radius: 10px;
		box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 36px rgba(24, 73, 129, 0.16);
		overflow: hidden;
		/*min-height: 480px;*/
		min-width: 280px;
		margin: 0 10px 45px;
		/*
		&:after {
			content: '';
			display: block;
			padding-top: 110%;
			background: url('./assets/img/bg/tariff.jpg') 50% 50% no-repeat;
			background-size: cover;
			filter: blur(15px);
		}
		*/
	    @mixin responsive-le-m {
	    	width: calc(50% - 20px);
	    	min-width: 320px;
	    }
	    @mixin responsive-le-s {
	    	width: calc(100% - 20px);
	    	min-width: 180px;
	    }
		&-inner {
			/*
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			*/
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: 35px 25px;
			/*background: $color-white-opacity-90;*/
			background: $color-white;
			z-index: 1;
		}
		&-title {
			position: relative;
			/*
			font-size: 28px;
			line-height: 34px;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 15px;
			padding-left: 35px;
			*/
			font-size: 20px;
			line-height: 28px;
			font-weight: bold;
			color: $color-blue;
			margin-bottom: 15px;
		}
		&-check {
			position: absolute;
			display: block;
			top: -10px;
			left: -25px;
			height: 45px;
			width: 45px;
			border: 1px solid $color-blue;
			border-radius: 10px;
			background: $color-white;
		}
		&.is-selected &-check:after {
			content: '';
			position: absolute;
			top: 2px;
			right: 2px;
			bottom: 2px;
			left: 2px;
			background: url('./assets/img/icons/check.svg') 50% 50% no-repeat;
			background-size: 24px 24px;
		}
		&-block {
			margin-bottom: 20px;
			&-name {
				font-size: 20px;
				line-height: 28px;
				font-weight: bold;
				color: $color-blue;
				margin-bottom: 10px;
			}
			&-param {
				position: relative;
				margin-bottom: 10px;
				font-size: 16px;
				line-height: 18px;
				padding-left: 35px;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					margin-top: -12px;
					left: 0;
					width: 24px;
					height: 24px;
					background-position: 50% 50%;
					background-repeat: no-repeat;
				}
				&.m--icon-clock:before {
					background: url('./assets/img/icons/param-clock.svg') 0 50% no-repeat;
					background-size: 24px 24px;
				}
				&.m--icon-fresh:before {
					background: url('./assets/img/icons/param-fresh.svg') 0 50% no-repeat;
					background-size: 24px 24px;
				}
				&.m--icon-food:before {
					background: url('./assets/img/icons/param-food.svg') 0 50% no-repeat;
					background-size: 24px 24px;
				}
				&.m--icon-childs:before {
					background: url('./assets/img/icons/param-childs.svg') 0 50% no-repeat;
					background-size: 24px 24px;
				}
				&.m--icon-wine:before {
					background: url('./assets/img/icons/param-wine.svg') 0 50% no-repeat;
					background-size: 24px 24px;
				}
			}
		}
		&-button {
			margin-left: auto;
			margin-right: auto;
			margin-top: auto;
			width: 160px;
		}
	}
	&__advanced {
		position: relative;
		display: flex;
		flex-direction: column;
		max-width: 930px;
		background: $color-white;
		border-radius: 10px;
		margin-bottom: 45px;
		box-shadow: 0px 4px 24px rgba(24, 73, 129, 0.2);
		&-item {
			position: relative;
			display: grid;
			border-bottom: 1px solid $color-lightgray;
			padding: 15px 40px 15px 25px;
			grid-template-columns: auto 200px 150px 100px;

		    @mixin responsive-le-m {
				grid-template-columns: auto 150px 120px 100px;
		    }

		    @mixin responsive-le-s {
				padding: 15px 20px;
				grid-template-columns: auto 120px 100px 100px;
		    }

		    @mixin responsive-le-xs {
		    	grid-template-areas: "A A A" "B C D";
				grid-template-columns: auto 90px 100px;
		    }

			&-name {
				font-size: 20px;
				line-height: 28px;
				font-weight: bold;
				@mixin responsive-le-xs {
					grid-area: A;
					margin-bottom: 10px;
				}
			}
			&-info {
				font-size: 16px;
				line-height: 28px;
				color: $color-middlegray;
				@mixin responsive-le-xs {
					grid-area: B;
				}
			}
			&-price {
				font-size: 20px;
				line-height: 28px;
				font-weight: bold;
				@mixin responsive-le-xs {
					grid-area: C;
				}
			}
			&-count {
				position: relative;
				display: flex;
				justify-content: flex-start;
				@mixin responsive-le-xs {
					grid-area: D;
				}
			}
			&-button {
				position: relative;
				display: block;
				width: 32px;
				height: 32px;
				padding: 0;
				line-height: 30px;
				font-size: 18px;
			}
			&-nums {
				width: 32px;
				text-align: center;
				line-height: 32px;
			}
		}
	}
}