.rooms {
	&__top {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	&__params {
		@mixin input
		position: relative;
		display: flex;
		align-items: center;
		max-width: 620px;
		margin-bottom: 40px;
		max-height: 52px;
		margin-right: 20px;
		&-button {
			margin: -14px -15px -14px 20px;
			border-radius: 0 4px 4px 0;
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			height: 50px;
			margin-left: auto;
		}
		&-dates,
		&-rooms {
			padding-left: 35px;
			padding-right: 20px;
			color: $color-middlegray;
		}
		&-dates {
			background: url('./assets/img/icons/calendar.svg') 0 50% no-repeat;
			background-size: 24px 24px;
		}
		&-rooms {
			background: url('./assets/img/icons/peoples.svg') 0 50% no-repeat;
			background-size: 24px 24px;
		}

	    @mixin responsive-le-xl {
	    }

	    @mixin responsive-le-l {
	    }

	    @mixin responsive-le-m {
	    }

	    @mixin responsive-le-s {
	    	margin-right: 0;
	    	max-width: none;
	    }

	    @mixin responsive-le-xs {
			max-height: none;
			flex-wrap: wrap;
			&-dates,
			&-rooms {
				width: 50%;
			}
			&-button {
				margin: 14px -15px -14px -15px;
				width: calc(100% + 28px);
			}
	    }

	    @mixin responsive-xxs {
			&-dates,
			&-rooms {
				width: 100%;
				margin-bottom: 14px;
			}
			&-button {
				margin-top: 0;
			}
	    }
	}
	&__included {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		color: $color-white;
		min-height: 50px;
		background: $color-blue;
		padding: 0 20px;
		border-radius: 4px;
		margin-top: 1px;
		margin-bottom: 40px;
		&-title {
			font-weight: 500;
			font-size: 24px;
			line-height: 30px;
			padding: 10px 25px 10px 0;
		}
		&-item {
			position: relative;
			display: block;
			font-weight: bold;
			font-size: 20px;
			line-height: 28px;
			padding: 10px 25px 10px 50px;
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				margin-top: -20px;
				left: 0;
				width: 40px;
				height: 40px;
				background: $color-white;
				border-radius: 50%;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: 26px 26px;
			}
			&.m--icon-spa:before {
				background-image: url('./assets/img/icons/include-spa.svg');
			}
			&.m--icon-snow:before {
				background-image: url('./assets/img/icons/include-snow.svg');
			}
			&.m--icon-pills:before {
				background-image: url('./assets/img/icons/include-pills.svg');
			}
			&.m--icon-lego:before {
				background-image: url('./assets/img/icons/include-lego.svg');
			}
		}

	    @mixin responsive-le-xl {
	    	width: 100%;
	    }

	    @mixin responsive-le-l {
	    }

	    @mixin responsive-le-m {
			&-title {
				width: 50%;
				margin-bottom: 10px;
			}
			&-item {
				width: 50%;
				margin-bottom: 10px;
			}
	    }

	    @mixin responsive-le-s {
			&-title {
				width: 100%;
			}
	    }

	    @mixin responsive-le-xs {
	    }
	}
	&__block {
		margin-bottom: 30px;
		&-title {
			color: $color-blue;
			margin: 60px auto 25px;
			max-width: $list-width;
		}
		&-navigate {
			position: relative;
			display: flex;
			justify-content: space-between;
			padding: 30px 0 60px;
			&-item {
				position: relative;
				display: block;
				font-weight: bold;
				font-size: 24px;
				color: $color-blue;
				&.is-active {
					font-size: 36px;
					text-decoration: none;
				}
				&:hover {
					text-decoration: none;
				}
			}
		}
		&-inner {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			padding: 60px 55px 20px;
			margin: 0 -65px 40px;
			background: $color-white;
		}
	    @mixin responsive-le-l {
	    	&-inner {
				padding: 60px 30px 20px;
				margin: 0 -40px 40px;
			}
	    }
	    @mixin responsive-le-m {
	    	&-inner {
				padding: 60px 20px 20px;
				margin: 0 -30px 40px;
			}
	    }
	    @mixin responsive-le-xs {
	    	&-inner {
				padding: 60px 10px 20px;
				margin: 0 -20px 40px;
			}
	    }
	    @mixin responsive-xxs {
	    	&-inner {
				padding: 60px 0 20px;
				margin: 0 -10px 40px;
			}
	    }
	}
	&__rows {
	}
	&__alert {
		font-size: 20px;
		font-weight: bold;
		color: $color-blue;
		width: 100%;
		margin: -30px 0 10px;
		&.m--no-margin {
			margin: 0;
		}
	}
	&__list {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 0 auto;
		width: 100%;
		max-width: $list-width;
	    @mixin responsive-le-m {
	    	justify-content: center;
	    }
	}
	&__item {
		position: relative;
		display: block;
		width: calc(33% - 20px);
		background: $color-middlewhite;
		border-radius: 10px;
		filter: drop-shadow(0px 4px 24px rgba(24, 73, 129, 0.2));
		overflow: hidden;
		/*min-height: 480px;*/
		min-width: 280px;
		margin: 0 10px 45px;
		&:after {
			/*
			content: '';
			display: block;
			padding-top: 110%;
			*/
		}
	    @mixin responsive-le-m {
	    	width: calc(49.9% - 20px);
	    	min-width: 340px;
	    }
	    @mixin responsive-le-xs {
	    	width: calc(90% - 20px);
	    	min-width: 180px;
	    }
		&-inner {
			position: relative;
		    display: flex;
		    flex-direction: column;
		    height: 100%;
			/*
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			*/
		}
		&-picture {
			position: relative;
			display: block;
			background: $color-white;
			&:after {
				content: '';
				display: block;
				padding-top: 60%;
			}
			&.m--disabled {
				filter: grayscale(1);
			}
			&-inner {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				overflow: hidden;
				img {
					position: relative;
					display: block;
					width: 100%;
					height: 100%;
					max-width: 100%;
					max-height: 100%;
					object-fit: cover;
				}
				.splide__track,
				.splide__list {
					height: 100%;
				}
				.splide__arrow {
					top: 0;
					height: 100%;
					border: none;
					border-radius: 0;
					transform: translateY(0);
					opacity: 0;
					background-color: $color-white-opacity-30;
					transition: opacity .2s linear;
					&--prev {
						left: 0;
					}
					&--next {
						right: 0;
					}
					&:hover {
						opacity: 1;
					}
				}
				.splide__arrow[disabled] {
					display: none;
				}
			}
		}
		&-label {
			position: absolute;
			display: block;
			top: 30px;
			left: 0;
			padding: 8px 15px 8px 46px;
			font-size: 16px;
			line-height: 24px;
			font-weight: 700;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			color: $color-white;
			background: $color-slightblue-opacity-80;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				margin-top: -12px;
				left: 12px;
				width: 24px;
				height: 24px;
				background: url('./assets/img/icons/info.svg') 50% 50% no-repeat;
				background-size: 24px 24px;
			}
			&.m--last {
				/*background: $color-yellow;*/
			}
		}
		&-info {
			padding: 20px;
			margin-top: auto;
			&-block {
				position: relative;
				display: flex;
				justify-content: space-between;
				margin-bottom: 25px;
				&:last-child {
					margin-top: auto;
					margin-bottom: 0;
				}
			}
		    @mixin responsive-le-xs {
		    	padding: 20px 10px;
		    }
		}
		&-name {
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;
		}
		&-subname {
			display: flex;
			font-size: 16px;
			line-height: 24px;
			color: $color-gray-opacity-40;
			margin-bottom: 10px;
		}
		&-params {

		}
		&-param {
			padding-left: 35px;
			color: $color-black;
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 10px;
			background-size: 24px 24px;
			background-repeat: no-repeat;
			background-position: 0 50%;
			background-size: 24px 24px;
			&.m--area {
				background-image: url('./assets/img/icons/area.svg');
			}
			&.m--persons {
				background-image: url('./assets/img/icons/people-gray.svg');
			}
			&.m--boom {
				background-image: url('./assets/img/icons/param-boom.svg');
			}
			&.m--gift {
				background-image: url('./assets/img/icons/param-gift.svg');
			}
			&.m--food {
				background-image: url('./assets/img/icons/param-food.svg');
			}
			&.m--fresh {
				background-image: url('./assets/img/icons/param-fresh.svg');
			}
			&.m--tea {
				background-image: url('./assets/img/icons/param-tea.svg');
			}
			&.m--spa {
				background-image: url('./assets/img/icons/param-spa.svg');
			}
			&.m--wine {
				background-image: url('./assets/img/icons/param-wine.svg');
			}
			&.m--lotus {
				background-image: url('./assets/img/icons/param-lotus.svg');
			}
			&.m--champagne {
				background-image: url('./assets/img/icons/param-champagne.svg');
			}
		}
		&-prices {

		}
		&-price {
			position: relative;
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 4px;
			text-align: right;
			&.m--new {
				color: $color-blue;
				span {
					font-size: 24px;
					font-weight: bold;
					&.m--currency {
						font-weight: 300;
					}
				}
			}
			&.m--old {
				margin-left: auto;
				color: $color-middlegray;
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					height: 0;
					border-bottom: 1px solid $color-middlegray;
				}
			}
		}
		&-included {
			font-weight: bold;
			font-size: 16px;
			line-height: 28px;
			text-transform: lowercase;
		}
		&-button {
			width: 160px;
			height: 44px;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			&:first-child {
				margin-right: 10px;
			}
		    @mixin responsive-le-l {
		    	/*width: 120px;*/
		    	padding: 10px 10px;
		    }
		    @mixin responsive-le-m {
		    	padding: 10px 20px;
		    	width: 160px;
		    }
		    @mixin responsive-le-xs {
		    	width: 48%;
		    }
		    &.m--counter {
		    	display: flex;
		    	justify-content: space-between;
		    	padding: 0;
		    }
		    &-counter {
		    	border: none;
		    	height: 100%;
		    	padding: 10px 15px;
		    	font-size: 24px;
		    	&.m--minus {
		    		border-radius: 22px 0 0 22px;
		    	}
		    	&.m--plus {
		    		border-radius: 0 22px 22px 0;
		    	}
			    @mixin responsive-le-l {
			    	padding: 10px 12px;
			    }
			    @mixin responsive-le-m {
			    	padding: 10px 15px;
			    }
			    @mixin responsive-le-xs {
			    	padding: 10px 20px;
			    }
		    }
		}
	}
	&__row {
		position: relative;
		padding: 24px 12px;
		border-bottom: 1px solid $color-lightgray;
		&-inner {
			position: relative;
			display: grid;
	        grid-template-columns: 420px 220px 230px 320px auto;
	        grid-template-areas: 'A B C D E';
		    @mixin responsive-le-xl {
		        grid-template-columns: 360px 170px 180px 300px auto;
		    }
		    @mixin responsive-le-l {
		        grid-template-columns: auto 170px 230px 300px;
		        grid-template-areas: 'A B C D'
		        					 'E E E E';
		    }
		}
		&-info {
			padding-right: 24px;
			grid-area: A;
		}
		&-dates {
			padding-right: 24px;
			grid-area: B;
		}
		&-tariffs {
			padding-right: 24px;
			grid-area: C;
		}
		&-peoples {
			padding-right: 24px;
			grid-area: D;
		    @mixin responsive-le-l {
		    	padding-right: 0;
		    }
		}
		&-prices {
			grid-area: E;
			&-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 24px;
			}
			&-name {
				padding-right: 24px;
			}
			&-value {
				font-weight: bold;
				white-space: nowrap;
				.field__input {
					width: 70px;
					margin-bottom: 0;
					input {
						padding: 12px 5px 12px 15px;
					}
				}
			}
		    @mixin responsive-le-l {
		    	.field {
		    		padding-top: 24px;
		    		display: flex;
		    		flex-wrap: wrap;
		    		justify-content: flex-start;
		    		&__label {
		    			width: 100%;
		    		}
		    	}
		    	&-item {
		    		padding-right: 48px;
		    		justify-content: flex-start;
		    	}
		    }
		}
		&-loader {
			position: absolute;
			display: flex;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			justify-content: center;
			align-items: center;
			background: $color-white-opacity-30;
			.spinner {
	            width: 50px;
	            height: 50px;
			}
		}
		&-name {
			display: block;
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 12px;
			color: $color-blue;
			&:hover {
				text-decoration: none;
			}
		}
		&-picture {
			position: relative;
			display: block;
			background: $color-white;
			&:after {
				content: '';
				display: block;
				padding-top: 60%;
			}
			&.m--disabled {
				filter: grayscale(1);
			}
			&-inner {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				overflow: hidden;
				border-radius: 4px;
				img {
					position: relative;
					display: block;
					width: 100%;
					height: 100%;
					max-width: 100%;
					max-height: 100%;
					object-fit: cover;
				}
			}
		}
		&-button&-button {
			font-size: 17px;
		}
		.form__field {
			margin-bottom: 0;
		}
	}
}