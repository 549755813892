.splide {

  &--ttb {
    > .splide__track > .splide__arrows, > .splide__arrows {
      .splide__arrow {
        left: 50%;
        transform: translate( -50%, 0 );

        &--prev {
          top: 1em;

          svg {
            transform: rotateZ( -90deg );
          }
        }

        &--next {
          top: auto;
          bottom: 1em;

          svg {
            transform: rotateZ( 90deg );
          }
        }
      }
    }

    > .splide__pagination {
      display: flex;
      flex-direction: column;
      bottom: 50%;
      left: auto;
      right: .5em;
      transform: translate( 0, 50% );
    }
  }
}