.form {
	position: relative;
	width: 100%;
	max-width: 720px;
	margin: 0 auto;
	&__row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		@mixin responsive-le-xs {
			margin-bottom: 20px;
		}
		&-title {
			text-transform: uppercase;
			width: 100%;
		    color: $color-black;
		}
		&-link {
			display: block;
			float: right;
			text-transform: lowercase;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: $color-middlegray;
			&:hover {
				text-decoration: none;
				color: $color-blue;
			}
		}
		&-add {
			display: flex;
			font-size: 24px;
			line-height: 28px;
			align-items: center;
			span {
				font-weight: 600;
			}
			button {
				margin-left: 10px;
			}
		}
		.m--width-66 {
			width: 66%;
		}
		.m--width-50 {
			width: 49%;
		}
		.m--width-33 {
			width: 32%;
		}
		.m--width-25 {
			width: 24%;
		}
		@mixin responsive-le-m {
			.m--width-66 {
				width: 49%;
			}
			.m--width-33 {
				width: 49%;
			}
		}
		@mixin responsive-le-xs {
			.m--width-66 {
				width: 100%;
			}
			.m--width-50 {
				width: 100%;
			}
			.m--width-33 {
				width: 100%;
			}
		}
	}
	&__field {
		width: 100%;
	}
	&__submit {
		display: flex;
		justify-content: center;
		margin-bottom: 32px;
	}
	&__price {
		position: relative;
		white-space: nowrap;
		color: $color-blue;
		font-size: 20px;
		margin-bottom: 40px;
		span {
			font-size: 24px;
			font-weight: bold;
			&.m--currency {
				font-weight: 300;
			}
		}
	}
}

.field {
	margin-bottom: 40px;
	@mixin responsive-le-xs {
		margin-bottom: 0;
	}
	&.m--no-margin {
		margin-bottom: 0;
	}
	&__label {
		margin-bottom: 20px;
		font-size: 24px;
		font-weight: 500;
		line-height: 28px;
		color: $color-blue;
		@mixin responsive-le-s {
			font-size: 18px;
			line-height: 24px;
		}
	}
	&__input {
		margin-bottom: 20px;
		input {
			@mixin input;
		}
		textarea {
			@mixin textarea;
		}
		select {
			@mixin select;
		}
		input[type=radio] {
			@mixin radiobox;
		}
	}
}