.splide {
  &__arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY( -50% );
    width: $arrow-button-size;
    height: $arrow-button-size;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-blue;
    padding: 0;
    background: $color-white-opacity-80;
    transition: background 0.2s;
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: url('./assets/img/icons/arrow-thumbs.svg') 50% 50% no-repeat;
      background-size: 24px 24px;
    }

    svg {
      display: none;
      width: $arrow-size;
      height: $arrow-size;
    }

    &:hover {
      cursor: pointer;
      background: $color-white;
    }

    &:focus {
      outline: none;
    }

    &--prev {
      left: -22px;

      svg {
        transform: scaleX( -1 );
      }
      &:after {
        transform: scaleX( -1 );
      }
    }

    &--next {
      right: -22px;
    }
  }
}