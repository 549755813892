.splide {
  &__list {
    margin: 0!important;
    padding: 0!important;
    width: max-content;
    will-change: transform;
  }

  &.is-active {
    .splide__list {
      display: flex;
    }
  }
}
