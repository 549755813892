@define-mixin __p {
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

@define-mixin h1 {
    @mixin __p;

    margin-top: 0;
    margin-bottom: 32px;
    font-size: 54px;
    line-height: 66px;
    font-weight: 700;
    color: $color-middlegray;

    @mixin responsive-le-l {
        font-size: 46px;
        line-height: 48px;
    }

    @mixin responsive-le-m {
        font-size: 34px;
        line-height: 42px;
    }

    @mixin responsive-le-s {
        font-size: 32px;
        line-height: 40px;
    }

    @mixin responsive-le-xs {
        font-size: 30px;
        line-height: 38px;
    }
}

@define-mixin h2 {
    @mixin __p;

    margin-top: 0;
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 60px;
    font-weight: 700;

    @mixin responsive-le-l {
        font-size: 34px;
        line-height: 42px;
    }

    @mixin responsive-le-m {
        font-size: 32px;
        line-height: 40px;
    }

    @mixin responsive-le-s {
        font-size: 28px;
        line-height: 36px;
    }

    @mixin responsive-le-xs {
        font-size: 26px;
        line-height: 34px;
    }
}

@define-mixin h3 {
    @mixin __p;

    margin-top: 0;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}

@define-mixin p {
    @mixin __p;

    margin-bottom: 16px;
}

@define-mixin ul {
    @mixin p;
}

@define-mixin ol {
    @mixin p;
}

@define-mixin hr {
    @mixin p;
}

@define-mixin a {
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    color: $color-text;


    &:hover {
        border-bottom-color: transparent;
    }
}

@define-mixin a--color $color {
    color: $color;
    border-color: rgba($color, 0.5);
}

@define-mixin table {
    /* dummy */
}

@define-mixin base {
    font: $font-size-base/$line-height-base $font-family-base;
    color: $color-base;
}

@define-mixin format {
    h1:not(.nostyle) {
        @mixin h1;
    }

    h2:not(.nostyle) {
        @mixin h2;
    }

    h3:not(.nostyle) {
        @mixin h3;
    }

    p:not(.nostyle) {
        @mixin p;
    }

    ul:not(.nostyle) {
        @mixin ul;
        & > li {
            padding: 0 0 0 35px;
            @mixin p;
        }
        & > li:before {
            content: '—';
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 35px;
            padding: 0;
            margin-left: -35px;
            font-size: 18px;
            font-weight: 700;
            color: $color-blue;
            line-height: 24px;
            text-align: left;
        }
    }

    ol:not(.nostyle) {
        @mixin ol;
        counter-reset: point;
        & > li {
            padding: 0 0 0 35px;
            @mixin p;
        }
        & > li:before {
            content: counter(point);
            counter-increment: point;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 35px;
            padding: 0;
            margin-left: -35px;
            font-size: 18px;
            font-weight: 700;
            color: $color-blue;
            line-height: 24px;
            text-align: left;
        }
    }

    hr:not(.nostyle) {
        @mixin hr;
    }

    a:not(.nostyle) {
        @mixin a;
    }

    table:not(.nostyle) {
        @mixin table;
    }

    b:not(.nostyle),
    strong:not(.nostyle) {
        font-weight: bold;
    }

    i:not(.nostyle),
    em:not(.nostyle) {
        font-style: italic;
    }

    u:not(.nostyle),
    ins:not(.nostyle) {
        text-decoration: underline;
    }

    strike:not(.nostyle),
    del:not(.nostyle) {
        text-decoration: line-through;
    }
}

@define-mixin text {
    @mixin format;

    font: $font-size-text/$line-height-text $font-family-text;
    color: $color-text;
}
