.multiselect {
    @mixin input;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    outline: none;
    min-height: 50px;
    &.is-open {
        border-radius: 4px 4px 0 0;
    }

    &.is-open-top {
        border-radius: 0 0 4px 4px;
    }

    &.is-disabled {
        cursor: default;
        color: $color-lightgray;
    }

    &.is-active {
        /*box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, #10B98130);*/
    }

    &.m--person {
        padding-left: 40px;
        background-image: url('./assets/img/icons/peoples.svg');
        background-position: 15px 50%;
        background-repeat: no-repeat;
        background-size: 24px 24px;
    }

    &-multiple-label,
    &-single-label,
    &-placeholder {
        display: flex;
        align-items: center;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        background: transparent;
        line-height: 24px;
        padding-left: 17px;
    }

    &.m--person &-multiple-label,
    &.m--person &-single-label,
    &.m--person &-placeholder {
        left: 40px;
    }

    &-placeholder {
        color: $color-middlegray;
    }

    &-caret {
        transform: rotate(0deg);
        transition: .3s transform;
        background-image: url('./assets/img/icons/arrow.svg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 14px 8px;
        width: 14px;
        height: 8px;
        margin: 0;
        position: relative;
        z-index: 10;
        flex-shrink: 0;
        flex-grow: 0;
        pointer-events: none;

        &.is-open {
            transform: rotate(180deg);
            pointer-events: auto;
        }
    }

    &-option {
        display: flex;
        box-sizing: border-box;
        text-decoration: none;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 15px;

        &.is-pointed {
            background: $color-lightgray;
            color: $color-gray;
        }

        &.is-disabled {
            color: $color-lightgray;
            cursor: not-allowed;
        }

        &.is-selected {
            background-image: url('./assets/img/icons/check.svg');
            background-position: calc(100% - 14px) 50%;
            background-repeat: no-repeat;
            background-size: 16px 12px;
        }

        &.is-selected.is-pointed {
            background-color: $color-lightgray;
        }

        &.is-selected.is-disabled {
            background-color: $color-lightgray;
        }
    }

}

.multiselect-search {
    width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    outline: none;
    box-sizing: border-box;
    border: 0;
    appearance: none;
    font-size: inherit;
    font-family: inherit;
    background: var(--ms-bg, #FFFFFF);
    border-radius: var(--ms-radius, 4px);
    padding-left: var(--ms-px, 0.875rem);
}

.multiselect-tags {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-wrap: wrap;
    margin: var(--ms-tag-my, 0.25rem) 0 0;
    padding-left: var(--ms-py, 0.5rem);
    align-items: center;
}

.multiselect-tag {
    background: var(--ms-tag-bg, #10B981);
    color: var(--ms-tag-color, #FFFFFF);
    font-size: var(--ms-tag-font-size, 0.875rem);
    line-height: var(--ms-tag-line-height, 1.25rem);
    font-weight: var(--ms-tag-font-weight, 600);
    padding: var(--ms-tag-py, 0.125rem) 0 var(--ms-tag-py, 0.125rem) var(--ms-tag-px, 0.5rem);
    border-radius: var(--ms-tag-radius, 4px);
    margin-right: var(--ms-tag-mx, 0.25rem);
    margin-bottom: var(--ms-tag-my, 0.25rem);
    display: flex;
    align-items: center;
    white-space: nowrap;

    &.is-disabled {
        padding-right: var(--ms-tag-px, 0.5rem);
        background: var(--ms-tag-bg-disabled, #9CA3AF);
        color: var(--ms-tag-color-disabled, #FFFFFF);
    }
}

.multiselect-tag-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--ms-tag-remove-py, 0.25rem) var(--ms-tag-remove-px, 0.25rem);
    margin: var(--ms-tag-remove-my, 0rem) var(--ms-tag-remove-mx, 0.125rem);
    border-radius: var(--ms-tag-remove-radius, 4px);
    
    &:hover {
        background: #00000010;
    }
}

.multiselect-tag-remove-icon {
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
                    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
    -webkit-mask-position: center;
                    mask-position: center;
    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
                    mask-size: contain;
    background-color: currentColor;
    opacity: 0.8;
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
}

.multiselect-tags-search-wrapper {
    display: inline-block;
    position: relative;
    margin: 0 var(--ms-tag-mx, 4px) var(--ms-tag-my, 4px);
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}

.multiselect-tags-search-copy {
    visibility: hidden;
    white-space: pre-wrap;
    display: inline-block;
    height: 1px;
    width: 100%;
}

.multiselect-tags-search {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0;
    appearance: none;
    outline: none;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    box-sizing: border-box;
    width: 100%;
}

.multiselect-spinner {
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'%3E%3C/path%3E%3C/svg%3E");
                    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'%3E%3C/path%3E%3C/svg%3E");
    -webkit-mask-position: center;
                    mask-position: center;
    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
                    mask-size: contain;
    background-color: var(--ms-spinner-color, #10B981);
    width: 1rem;
    height: 1rem;
    z-index: 10;
    margin: 0 var(--ms-px, 0.875rem) 0 0;
    animation: multiselect-spin 1s linear infinite;
    flex-shrink: 0;
    flex-grow: 0;
}

.multiselect-clear {
    padding: 0 var(--ms-px, 0.875rem) 0 0px;
    position: relative;
    z-index: 10;
    opacity: 1;
    transition: .3s;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;

    &:hover .multiselect-clear-icon {
        background-color: var(--ms-clear-color-hover, #000000);
    }
}

.multiselect-clear-icon {
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
                    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
    -webkit-mask-position: center;
                    mask-position: center;
    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
                    mask-size: contain;
    background-color: var(--ms-clear-color, #999999);
    width: 0.625rem;
    height: 1.125rem;
    display: inline-block;
    transition: .3s;
}

.multiselect-dropdown {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: 0;
    transform: translateY(100%);
    border: 1px solid $color-blue;
    margin-top: -1px;
    max-height: 10rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 100;
    background: $color-white;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 4px 4px;
    outline: none;

    &.is-top {
        transform: translateY(-100%);
        top: 1px;
        bottom: auto;
        flex-direction: column-reverse;
        border-radius: 4px 4px 0 0;
    }

    &.is-hidden {
        display: none;
    }
}

.multiselect-options {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    max-height: var(--ms-max-height, 10rem);

    &.is-top {
        flex-direction: column-reverse;
    }
}

.multiselect-no-options,
.multiselect-no-results {
    padding: var(--ms-option-py, 0.5rem) var(--ms-option-px, 0.75rem);
    color: var(--ms-empty-color, #4B5563);
}

.multiselect-fake-input {
    background: transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    border: 0;
    padding: 0;
    font-size: 0;
    outline: none;

    &:active, &:focus {
        outline: none;
    }
}

.multiselect-spacer {
    display: none;
}

@keyframes multiselect-spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}