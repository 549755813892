.app {
	min-height: 100vh;
	&__block {
		display: flex;
		flex-direction: column;
		padding: 40px 65px;
		min-height: 100vh;
		max-width: $max-width;
		margin-left: auto;
		margin-right: auto;
		&.m--fixed-footer {
			padding-bottom: 130px;
		}
	    @mixin responsive-le-l {
	    	padding-left: 40px;
	    	padding-right: 40px;
	    }
	    @mixin responsive-le-m {
	    	padding-left: 30px;
	    	padding-right: 30px;
	    }
	    @mixin responsive-le-xs {
	    	padding-left: 20px;
	    	padding-right: 20px;
	    }
	    @mixin responsive-xxs {
	    	padding-left: 10px;
	    	padding-right: 10px;
	    }
	}
	&__block.m--fixed-footer &__footer {
		position: fixed;
		display: block;
		bottom: 0;
		left: 0;
		right: 0;
		border: none;
		padding: 0;
		background: $color-white;
		filter: drop-shadow(0px -6px 30px rgba(24, 73, 129, 0.4));
		z-index: 10;
	}
	&__header {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 30px;
		border-bottom: 1px solid $color-middlegray;
		&-title {
			text-align: center;
			margin: 0 auto;
		}
		&-back {
			.spinner {
				margin-right: 10px;
				.path {
					stroke: $color-white;
				}
			}
		}
		&-close {
		}
		&.m--steps {
			border-bottom: none;
		}
		&-steps {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			height: 10px;
			border-radius: 5px;
			background: $color-darkwhite;
			box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.1);
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				border-radius: 5px;
				background-color: $color-blue;
		        background-image: url('./assets/img/bg/step.svg');
		        background-position: 50% 50%;
		        background-repeat: repeat-x;
			}
			&.m--step-1:after {
				width: calc(100% / $steps * 1);
			}
			&.m--step-2:after {
				width: calc(100% / $steps * 2);
			}
			&.m--step-3:after {
				width: calc(100% / $steps * 3);
			}
			&.m--step-4:after {
				width: 100%;
			}
		}
		&-step {
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -20px;
			margin-left: -20px;
			width: 40px;
			height: 40px;
			background: $color-white;
			border: 2px solid $color-lightgray;
			border-radius: 50%;
			font-size: 24px;
			font-weight: bold;
			line-height: 36px;
			text-align: center;
			color: $color-lightgray;
			z-index: 10;
			&.m--step-1 {
				left: calc(100% / $steps * 1);
			}
			&.m--step-2 {
				left: calc(100% / $steps * 2);
			}
			&.m--step-3 {
				left: calc(100% / $steps * 3);
			}
			&.m--active {
				border-color: $color-blue;
				color: $color-blue;
			}
		}

	    @mixin responsive-le-l {
			&-back&-back {
				font-size: 16px;
				line-height: 18px;
				padding: 6px 15px;
				height: 38px;
			}
			&-close&-close {
				font-size: 16px;
				line-height: 18px;
				padding: 6px 15px;
				height: 38px;
			}
	    }

	    @mixin responsive-le-m {
			&-back&-back {
				font-size: 0;
				padding: 0;
				width: 38px;
				span {
					padding: 38px;
					&:before {
						left: 50%;
						margin-left: -15px;
					}
				}
			}
			&-close&-close {
				font-size: 0;
				padding: 0;
				width: 38px;
				span {
					padding: 0;
					&:before {
						left: 50%;
						margin-left: -15px;
					}
				}
			}
	    }

	    @mixin responsive-le-s {
			&-back {
			}
			&-close&-close {
			}
			&-steps {
				height: 6px;
			}
			&-step {
				margin-top: -12px;
				margin-left: -12px;
				width: 24px;
				height: 24px;
				font-size: 16px;
				line-height: 20px;
			}
	    }

	    @mixin responsive-le-xs {
			&-back {
			}
			&-close&-close {
			}
	    }

	}
	&__body {
		padding: 70px 0 50px;
		&-inner {

		}
	}
	&__footer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: auto;
		padding-top: 40px;
		border-top: 1px solid $color-middlegray;
		&.is-fixed {
			position: fixed;
			display: block;
			bottom: 0;
			left: 0;
			right: 0;
			border: none;
			padding: 0;
			background: $color-white;
			filter: drop-shadow(0px -6px 30px rgba(24, 73, 129, 0.4));
		}
	}
}