.specials {
	padding: 0;
	margin-bottom: 20px;
	&__title {
		color: $color-blue;
		margin-bottom: 55px;
	}
	&__block {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0;
	}
	&__item {
		position: relative;
		display: block;
		width: calc(50% - 55px);
		min-width: 250px;
		margin: 0 10px 20px 45px;

	    @mixin responsive-le-m {
			width: calc(70% - 45px);
			margin-bottom: 45px;
	    }

	    @mixin responsive-le-s {
			width: calc(100% - 45px);
			margin-right: 0;
	    }

		&.is-disabled {
			opacity: 0.6;
			filter: grayscale(100%);
		}

		&-inner {
			position: relative;
			display: block;
			padding: 10px 15px;
			background: $color-blue;
			border-radius: 20px;
			overflow: hidden;
			min-height: 240px;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			&:after {
				content: '';
				position: absolute;
				display: block;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: linear-gradient(90deg, $color-blue 0%, rgba(24, 73, 129, 0) 100%);
				z-index: 1;
			}
		}
		&-bg {
			position: absolute;
			display: block;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			img {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
			}
		}
		&-title {
			position: relative;
			font-size: 20px;
			line-height: 28px;
			font-weight: bold;
			color: $color-white;
			margin-bottom: 30px;
			z-index: 2;
		}
		&-check {
			position: absolute;
			display: block;
			top: 0;
			left: -45px;
			height: 32px;
			width: 32px;
			border: 1px solid $color-blue;
			border-radius: 10px;
			background: $color-white;
		}
		&.is-selected &-check:after {
			content: '';
			position: absolute;
			top: 2px;
			right: 2px;
			bottom: 2px;
			left: 2px;
			background: url('./assets/img/icons/check.svg') 50% 50% no-repeat;
			background-size: 20px 20px;
		}
		&.is-disabled &-check {
			cursor: default;
		}
		&-block {
			position: relative;
			padding-right: 30%;
			font-size: 16px;
			line-height: 24px;
			color: $color-white;
			z-index: 2;
			@mixin responsive-le-s {
				padding-right: 0;
			}
		}
	}
}