.loader {
    position: relative;
    display: flex;
    align-items: center;
    &__spinner {
        position: relative;
        display: block;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -25px 0 0 -25px;
            width: 50px;
            height: 50px;
        }
    }
    &__label {
        color: $color-blue;
        font-size: 20px;
        font-weight: 700;
    }
}
.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 24px;
    height: 24px;
    .path {
        stroke: $color-blue;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
    &.m--white {
        .path {
            stroke: $color-white;
        }
    }
}
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
