.splide {

  &--rtl {
    > .splide__track > .splide__arrows, > .splide__arrows {
      .splide__arrow {
        &--prev {
          right: 1em;
          left: auto;

          svg {
            transform: scaleX( 1 );
          }
        }

        &--next {
          left: 1em;
          right: auto;

          svg {
            transform: scaleX( -1 );
          }
        }
      }
    }
  }
}