.basket {
	&__block {
		display: flex;
		padding: 30px 65px;
		justify-content: space-between;
		align-items: center;
		max-width: 1410px;
		margin: 0 auto;
		&-params {
			position: relative;
			padding-left: 80px;
			&:before {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				top: 50%;
				margin-top: -24px;
				width: 54px;
				height: 48px;
				background: url('./assets/img/icons/basket.svg') 50% 50% no-repeat;
			}
			&-dates,
			&-rooms {
				padding-left: 35px;
				padding-right: 20px;
				color: $color-middlegray;
			}
			&-dates {
				margin-bottom: 20px;
				background: url('./assets/img/icons/calendar-gray.svg') 0 50% no-repeat;
				background-size: 24px 24px;
			}
			&-rooms {
				background: url('./assets/img/icons/peoples-gray.svg') 0 50% no-repeat;
				background-size: 24px 24px;
			}
		}
		&-control {
			display: flex;
			align-items: center;
		}
		&-sum {
			margin-left: auto;
			margin-right: 25px;
			white-space: nowrap;
			span {
				font-size: 54px;
				line-height: 54px;
				font-weight: bold;
				&.m--currency {
					font-weight: 400;
				}
			}
		}
		&-buttons {

		}
		&-button {
			width: 180px;
			height: 48px;
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;
		}

	    @mixin responsive-le-xl {
	    }

	    @mixin responsive-le-l {
	    	padding-left: 40px;
	    	padding-right: 40px;
	    }

	    @mixin responsive-le-m {
	    	padding-left: 30px;
	    	padding-right: 30px;
	    	&-sum {
	    		span {
					font-size: 34px;
					line-height: 34px;
				}
	    	}
	    }

	    @mixin responsive-le-s {
	    	padding-top: 15px;
	    	padding-bottom: 15px;
	    	&-params {
	    		&-dates {
	    			margin-bottom: 10px;
	    		}
	    	}
	    	&-control {
	    		flex-direction: column;
	    		align-items: flex-end;
	    	}
	    	&-sum {
	    		margin-right: 0;
	    		margin-bottom: 10px;
	    	}
			&-button {
				width: 160px;
				height: 44px;
				font-size: 20px;
			}
	    }

	    @mixin responsive-le-xs {
	    	flex-wrap: wrap;
	    	padding-left: 20px;
	    	padding-right: 20px;
	    	&-control {
	    		flex-direction: row;
	    		width: 100%;
	    		justify-content: space-between;
	    		align-items: flex-end;
	    		align-items: center;
	    		padding-top: 20px;
	    	}
	    	&-sum {
	    		margin-left: 0;
	    		margin-bottom: 0;
	    	}
			&-buttons {
				margin: 0 0 0 auto;
			}
	    }

	    @mixin responsive-xxs {
	    	padding-left: 10px;
	    	padding-right: 10px;
	    }

	}
}