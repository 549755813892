.room {
	position: relative;
	display: block;
	width: calc(100% - 20px);
	padding: 30px 50px;
	margin: 0 10px 45px;
	background: $color-white;
	border-radius: 10px;
	filter: drop-shadow(0px 4px 24px rgba(24, 73, 129, 0.2));

    @mixin responsive-le-l {
		padding: 30px;
    }

    @mixin responsive-le-s {
    	padding: 20px;
    }

	&.m--light {
		padding: 0;
		background: transparent;
		border-radius: 0;
		filter: none;
		max-width: $list-width;
		margin: 0;
		/*
		margin-left: auto;
		margin-right: auto;
		*/
	}
	&__arrow {
		position: absolute;
		top: -45px;
		left: 50%;
		margin-left: -37px;
		width: 74px;
		height: 74px;
		background: url('./assets/img/icons/room-arrow-more.svg') 50% 50% no-repeat;
		background-size: 74px 74px;
	}
	&__inner {
		display: flex;
		flex-direction: column;
	}
	&__block {
		position: relative;
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
	    @mixin responsive-le-m {
	    	flex-wrap: wrap;
	    }
	}
	&.m--light &__block {
		flex-wrap: wrap;
	}
	&__gallery {
		width: 53%;
		&-picture {
			position: relative;
			display: block;
			border-radius: 4px;
			overflow: hidden;
			margin-bottom: 25px;
			&:after {
				content: '';
				display: block;
				padding-top: 53%;
			}
			&-inner {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				overflow: hidden;
				a {
					cursor: pointer;
				}
				img {
					position: relative;
					display: block;
					width: 100%;
					height: 100%;
					max-width: 100%;
					max-height: 100%;
					object-fit: cover;
				}
				.splide__track,
				.splide__list {
					height: 100%;
				}
				.splide__arrow {
					top: 0;
					height: 100%;
					border: none;
					border-radius: 0;
					transform: translateY(0);
					opacity: 0;
					background-color: $color-white-opacity-30;
					transition: opacity .2s linear;
					&--prev {
						left: 0;
					}
					&--next {
						right: 0;
					}
					&:hover {
						opacity: 1;
					}
				}
				.splide__arrow[disabled] {
					display: none;
				}
			}
		}
		&-thumbs {
			.splide__list {
				height: 110px;
			}
			padding: 0 22px;
			.splide__slide {
				border-radius: 4px;
			}
		}
	    @mixin responsive-le-m {
	    	width: 100%;
	    }
	}
	&.m--light &__gallery {
		width: 440px;
	    @mixin responsive-le-m {
	    	width: calc(50% - 30px);
	    }
	    @mixin responsive-le-s {
	    	width: 100%;
	    }
	}
	&.m--light &__gallery-picture {
		margin-bottom: 0;
	}
	&.m--light &__gallery-picture:after {
		padding-top: 63%;
	}
	&__button {
		width: 160px;
		height: 44px;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 12px;
	    @mixin responsive-le-l {
	    	/*width: 120px;*/
	    }
	    @mixin responsive-le-m {
	    	width: 160px;
	    }
	    @mixin responsive-le-xs {
	    	/*width: 48%;*/
	    }
	    &.m--counter {
	    	display: flex;
	    	justify-content: space-between;
	    	padding: 0;
	    }
	    &-counter {
	    	border: none;
	    	height: 100%;
	    	padding: 10px 15px;
	    	font-size: 24px;
	    	&.m--minus {
	    		border-radius: 22px 0 0 22px;
	    	}
	    	&.m--plus {
	    		border-radius: 0 22px 22px 0;
	    	}
	    }
	}

	&__info {
		width: 45%;
		&.m--border-bottom {
			border-bottom: 1px solid $color-middlegray;
		}
		&-name {
			font-weight: 700;
			font-size: 34px;
			line-height: 40px;
			@mixin responsive-le-m {
				font-size: 30px;
				line-height: 36px;
			}
		}
		&-subname {
			font-size: 16px;
			line-height: 24px;
			color: $color-gray-opacity-40;
			margin-bottom: 10px;
		}
		&-params {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			/*margin-bottom: 10px;*/
			&.m--row {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				flex-wrap: wrap;
			}
			&.m--start {
				justify-content: flex-start;
			}
			&-dates,
			&-rooms {
				padding-left: 35px;
				width: 220px;
				color: $color-middlegray;
				margin-bottom: 10px;
			}
			&-dates {
				background: url('./assets/img/icons/calendar.svg') 0 50% no-repeat;
				background-size: 24px 24px;
			}
			&-rooms {
				background: url('./assets/img/icons/peoples.svg') 0 50% no-repeat;
				background-size: 24px 24px;
			}
		}
		&-param {
			padding-left: 35px;
			color: $color-black;
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 10px;
			&.m--area {
				background: url('./assets/img/icons/area.svg') 0 50% no-repeat;
				background-size: 24px 24px;
			}
			&.m--persons {
				background: url('./assets/img/icons/people-gray.svg') 0 50% no-repeat;
				background-size: 24px 24px;
			}
			&.m--breakfast {
				background: url('./assets/img/icons/breakfast.svg') 0 50% no-repeat;
				background-size: 24px 24px;
			}
		}
		&-params.m--row &-param {
			/*width: 180px;*/
			white-space: nowrap;
			padding-right: 10px;
		}
		&-block {
			position: relative;
			display: flex;
			justify-content: space-between;
			padding-bottom: 25px;
			border-bottom: 1px solid $color-middlegray;
			margin-bottom: 20px;
			&.m--no-border {
				border-bottom: none;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			&.m--row {
				max-width: 540px;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			    @mixin responsive-le-s {
			    	max-width: none;
			    }
			}
			&.m--no-max-width {
		    	max-width: none;
			}
		    @mixin responsive-le-xs {
    			flex-wrap: wrap;
    		}
		}
		&-prices {

		}
		&-price {
			position: relative;
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 4px;
			text-align: right;
			&.m--big {
				color: $color-blue;
				margin-top: 20px;
				margin-bottom: -50px;
				span {
					font-size: 54px;
					font-weight: bold;
					line-height: 62px;
					&.m--currency {
						font-weight: 300;
					}
				}
			}
			&.m--blue {
				color: $color-blue;
				white-space: nowrap;
				span {
					font-weight: bold;
					font-size: 34px;
					line-height: 40px;
					&.m--currency {
						font-weight: 300;
					}
				}
				@mixin responsive-le-m {
					span {
						font-size: 30px;
						line-height: 36px;
					}
				}
			}
			&.m--new {
				color: $color-blue;
				span {
					font-size: 24px;
					font-weight: bold;
					&.m--currency {
						font-weight: 300;
					}
				}
			}
			&.m--old {
				color: $color-middlegray;
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					height: 0;
					border-bottom: 1px solid $color-middlegray;
				}
			}
		}
		&-included {
			font-weight: bold;
			font-size: 16px;
			line-height: 28px;
		}
		&-description {
			padding: 15px 0;
		}
		&-buttons {
			display: flex;
			align-items: center;
		    @mixin responsive-le-xs {
		    	width: 100%;
		    	justify-content: flex-end;
		    }
		}
	    @mixin responsive-le-m {
	    	padding-top: 40px;
	    	width: 100%;
	    }
	}
	&.m--light &__info {
		width: calc(100% - 480px);
		/*width: auto;*/
		/*padding-left: 40px;*/
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	    @mixin responsive-le-m {
	    	width: 50%;
	    }
	    @mixin responsive-le-s {
	    	width: 100%;
	    }
	    &-main {
	    	width: calc(100% - 380px);
		    @mixin responsive-le-xl {
		    	width: 100%;
		    }
	    }
	}
	&.is-opened &__info {
		border-bottom: 1px solid $color-middlegray;
	}

	&__persons {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 380px;
		padding-top: 25px;
		padding-left: 20px;
	    @mixin responsive-le-xl {
	    	padding-top: 0;
	    	padding-left: 0;
	    	width: 100%;
	    }
		&-item {
			position: relative;
			display: grid;
			border-bottom: 1px solid $color-lightgray;
			padding: 15px 20px;
			grid-template-columns: auto 100px;

			&.m--read-only {
				display: flex;
				padding: 0;
				border-bottom: none;
			}

		    @mixin responsive-le-m {
				grid-template-columns: auto 100px;
		    }

		    @mixin responsive-le-s {
				padding: 15px 20px;
				grid-template-columns: auto 100px;
		    }

		    @mixin responsive-le-xs {
				grid-template-columns: auto 100px;
		    }

			&-name {
				font-size: 16px;
				line-height: 24px;
				@mixin responsive-le-xs {
					margin-bottom: 10px;
				}
			}
			&.m--read-only &-name {
				position: relative;
				line-height: 32px;
				&:after {
					content: ':';
				}
			}
			&-count {
				position: relative;
				display: flex;
				justify-content: flex-start;
			}
			&-button {
				position: relative;
				display: block;
				width: 32px;
				height: 32px;
				padding: 0;
				line-height: 30px;
				font-size: 18px;
			}
			&-nums {
				width: 32px;
				text-align: center;
				line-height: 32px;
			}
			&.m--read-only &-nums {
				font-weight: 700;
				width: auto;
				padding-left: 5px;
			}
		}
	}

	&__button {
		&-more {
			margin-top: 20px;
			height: 44px;
		}
		&-delete {
			margin-top: 20px;
			height: 44px;
			margin-left: 20px;
		}
		&-upgrade {
			margin-top: 20px;
			height: 44px;
			margin-left: 30px;
			/*margin-left: auto;*/
		}
	}
	&.is-opened &__button-more {
		display: none;
	}
	&__more {
		display: none;
		&-description {
			padding-bottom: 45px;
		}
		&-buttons {
			text-align: center;
			padding-top: 50px;
		}
		&-close {
			position: relative;
			display: inline-block;
			font-size: 24px;
			line-height: 24px;
			font-weight: bold;
			color: $color-blue;
			padding-left: 35px;
			transition: color 0.5s;
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				margin-top: -12px;
				left: 0;
				height: 24px;
				width: 24px;
				background: url('./assets/img/icons/arrow.svg') 50% 50% no-repeat;
				background-size: 24px auto;
				transform: scaleY( -1 );
			}
			&:hover {
				text-decoration: none;
				color: $color-gray;
			}
		}
	}
	&__buttons {
		text-align: center;
		padding: 20px 0 20px;
		display: flex;
		width: 100%;
    	align-items: flex-end;
	    @mixin responsive-le-xs {
	    	padding-top: 0;
	    	padding-bottom: 0;
	    }
		&-upgrade {
			position: relative;
			display: flex;
			justify-content: flex-end;
			margin-left: auto;
			flex-wrap: wrap;
			padding-left: 30px;
		}
		&-price {
			position: relative;
			font-size: 24px;
			line-height: 28px;
			margin-top: 20px;
			margin-left: auto;
			color: $color-blue;
			align-self: center;
			white-space: nowrap;
		    @mixin responsive-le-xs {
	    		margin-top: 0;
		    }
		}
		&-close {
			position: relative;
			display: inline-block;
			font-size: 24px;
			line-height: 24px;
			font-weight: bold;
			color: $color-blue;
			padding-left: 35px;
			transition: color 0.5s;
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				margin-top: -12px;
				left: 0;
				height: 24px;
				width: 24px;
				background: url('./assets/img/icons/arrow.svg') 50% 50% no-repeat;
				background-size: 24px auto;
				transform: scaleY( -1 );
			}
			&:hover {
				text-decoration: none;
				color: $color-gray;
			}
		}
	}
	&.is-opened &__more {
		display: block;
	}
	&__params {
		position: relative;
		display: flex;
		justify-content: space-between;
		&-block {
			position: relative;
			display: block;
			width: 30%;
		}
		&-title {
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 20px;
			color: $color-blue;
		}

	    @mixin responsive-le-s {
	    	flex-wrap: wrap;
			&-block {
				width: 48%;
		    	margin-bottom: 20px;
			}
	    }

	    @mixin responsive-le-xs {
			&-block {
				width: 100%;
			}
	    }
	}
	&__param {
		position: relative;
		padding-left: 35px;
		font-size: 16px;
		line-height: 24px;
		color: $color-lightblack;
		margin-bottom: 12px;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			margin-top: -12px;
			left: 0;
			width: 24px;
			height: 24px;
			background-position: 50% 50%;
			background-size: contain;
			background-repeat: no-repeat;
		}
		&.m--param-tv:before {
			background-image: url('./assets/img/icons/param-tv.svg');
		}
		&.m--param-table:before {
			background-image: url('./assets/img/icons/param-table.svg');
		}
		&.m--param-cupboard:before {
			background-image: url('./assets/img/icons/param-cupboard.svg');
		}
		&.m--param-teapot:before {
			background-image: url('./assets/img/icons/param-teapot.svg');
		}
		&.m--param-icebox:before {
			background-image: url('./assets/img/icons/param-icebox.svg');
		}
		&.m--param-bed:before {
			background-image: url('./assets/img/icons/param-bed.svg');
		}


		&.m--param-bathroom:before {
			background-image: url('./assets/img/icons/param-bathroom.svg');
		}
		&.m--param-bath:before {
			background-image: url('./assets/img/icons/param-bath.svg');
		}
		&.m--param-bidet:before {
			background-image: url('./assets/img/icons/param-bidet.svg');
		}
		&.m--param-cap:before {
			background-image: url('./assets/img/icons/param-cap.svg');
		}
		&.m--param-hygiene:before {
			background-image: url('./assets/img/icons/param-hygiene.svg');
		}
		&.m--param-slippers:before {
			background-image: url('./assets/img/icons/param-slippers.svg');
		}
		&.m--param-hairdryer:before {
			background-image: url('./assets/img/icons/param-hairdryer.svg');
		}


		&.m--param-internet:before {
			background-image: url('./assets/img/icons/param-internet.svg');
		}
		&.m--param-phone:before {
			background-image: url('./assets/img/icons/param-phone.svg');
		}
		&.m--param-tea:before {
			background-image: url('./assets/img/icons/param-tea.svg');
		}

		&.m--param-volley:before {
			background-image: url('./assets/img/icons/param-volley.svg');
		}
		&.m--param-mangal:before {
			background-image: url('./assets/img/icons/param-mangal.svg');
		}
		&.m--param-tennis:before {
			background-image: url('./assets/img/icons/param-tennis.svg');
		}
		&.m--param-lego:before {
			background-image: url('./assets/img/icons/param-lego.svg');
		}
		&.m--param-pills:before {
			background-image: url('./assets/img/icons/param-pills.svg');
		}
		&.m--param-spa:before {
			background-image: url('./assets/img/icons/param-spa.svg');
		}
		&.m--param-bike:before {
			background-image: url('./assets/img/icons/param-bike.svg');
		}
		&.m--param-snow:before {
			background-image: url('./assets/img/icons/param-snow.svg');
		}

		&.m--param-conditioner:before {
			background-image: url('./assets/img/icons/param-conditioner.svg');
		}
		&.m--param-dishwasher:before {
			background-image: url('./assets/img/icons/param-dishwasher.svg');
		}
		&.m--param-induction:before {
			background-image: url('./assets/img/icons/param-induction.svg');
		}
		&.m--param-tableware:before {
			background-image: url('./assets/img/icons/param-tableware.svg');
		}
		&.m--param-shower:before {
			background-image: url('./assets/img/icons/param-shower.svg');
		}
		&.m--param-shower-2:before {
			background-image: url('./assets/img/icons/param-shower-2.svg');
		}
		&.m--param-sheet:before {
			background-image: url('./assets/img/icons/param-sheet.svg');
		}

		&.m--param-room:before {
			background-image: url('./assets/img/icons/param-room.svg');
		}
		&.m--param-sauna:before {
			background-image: url('./assets/img/icons/param-sauna.svg');
		}
		&.m--param-pool:before {
			background-image: url('./assets/img/icons/param-pool.svg');
		}

		&.m--param-parking-1:before {
			background-image: url('./assets/img/icons/param-parking-1.svg');
		}
		&.m--param-parking-2:before {
			background-image: url('./assets/img/icons/param-parking-2.svg');
		}
		&.m--param-lotus:before {
			background-image: url('./assets/img/icons/param-lotus.svg');
		}
		&.m--param-champagne:before {
			background-image: url('./assets/img/icons/param-champagne.svg');
		}
	}
	&__tariffs {
		margin: 0 auto;
		max-width: $list-width;
	}
}