.splide {
  &--fade {
    > .splide__track {
      > .splide__list {
        display: block;

        > .splide__slide {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          opacity: 0;

          &.is-active {
            position: relative;
            z-index: 1;
            opacity: 1;
          }
        }
      }
    }
  }
}