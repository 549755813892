*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    min-width: 100%;
    min-height: 100%;
    overflow-x: auto;
    &.with-fancybox {
        overflow: hidden;
    }
}

body {
    @mixin base;

    position: relative;
    min-width: 320px;
    min-height: 100%;
    background-color: $color-middlewhite;
}
.container {
    padding-left: 74px;
    padding-right: 20px;
    max-width: 960px;
    width: 100%;
}

.h1 {
    @mixin h1;
}

.h2 {
    @mixin h2;
}

.h3 {
    @mixin h3;
}

.p {
    @mixin p;
}

.ul {
    @mixin ul;
}

.ol {
    @mixin ol;
}

.hr {
    @mixin hr;
}

.a {
    @mixin a;
    @mixin a--color $color-base;

    &--green {
        @mixin a--color $color-green;
    }

    &--yellow {
        @mixin a--color $color-yellow;
    }

    &--red {
        @mixin a--color $color-red;
    }

    &--purple {
        @mixin a--color $color-purple;
    }
}

.table {
    @mixin table;
}

.base {
    @mixin base;
}

.format {
    @mixin format;
}

.content {
    @mixin text;
}

.input {
    @mixin input;
}

.select {
    @mixin select;
}

.autocomplete {
    /*@mixin autocomplete;*/
}

.textarea {
    @mixin textarea;
}

.checkbox {
    @mixin checkbox;
}

.radiobox {
    @mixin radiobox;
}

.button {
    @mixin button;
    @mixin button--color $color-lightblack, $color-white, $color-lightblack;

    &.m--blue {
        @mixin button--color $color-blue, $color-blue, $color-white;
        &:hover {
            @mixin button--color $color-middleblue, $color-middleblue, $color-white;
        }
        &:active,
        &.is-active {
            @mixin button--color $color-lightblue, $color-lightblue, $color-white;
        }
    }

    &.m--blue-outline {
        @mixin button--color $color-blue, $color-white, $color-blue;
        &:hover {
            @mixin button--color $color-blue, $color-blue-opacity-04, $color-blue;
        }
        &:active,
        &.is-active {
            @mixin button--color $color-blue, $color-blue-opacity-16, $color-blue;
        }
    }

    &__spinner {
        margin-right: 12px;
    }
}

.color {
    &-blue {
        color: $color-blue !important;
    }

    &-green {
        color: $color-green !important;
    }

    &-yellow {
        color: $color-yellow !important;
    }

    &-red {
        color: $color-red !important;
    }

    &-purple {
        color: $color-purple !important;
    }
}

:root {
    --fancybox-bg: $color-blue-opacity-60;
    --carousel-button-bg: $color-blue;
    --carousel-button-border-radius: 0;
    --fancybox-thumbs-border-radius: 4px;
    --fancybox-accent-color: $color-blue;
}
.fancybox__container {
    --carousel-button-height: 44px;
    --carousel-button-width: 44px;
    --carousel-button-svg-width: 20px;
    --carousel-button-svg-height: 20px;
}
.fancybox__toolbar {
    --carousel-button-svg-width: 18px;
    --carousel-button-svg-height: 18px;
}
.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
    border-width: 4px;
}
/*
.fancybox__nav .carousel__button {
    background: $color-blue;
}
*/