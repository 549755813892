.splide {
  &--ttb {
    > .splide__track {
      > .splide__list {
        display: block;
      }
    }

    > .splide__pagination {
      width: auto;
    }
  }
}